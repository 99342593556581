import React, { useState } from 'react';
import styled from '@emotion/styled';
import Headroom from 'react-headroom';
import Container from './Container';
import Link from '../Link';
import logo from '../../assets/images/logo.svg';
import logoDark from '../../assets/images/logo-dark.svg';
import getZIndex from '../../styles/zIndex';
import { MONO_SYSTEM_FONTS, SYSTEM_FONTS } from '../../styles/typography';
import {
  ContentfulCategoryEdge,
  ContentfulHeaderLinks,
} from '../../graphql-types';
import { useHeaderLinks } from '../../hooks';
import { ReactComponent as PhoneIcon } from '../../assets/svgs/header/phone.svg';
import LanguageSelect from './LanguageSelect';
import { useIntl } from 'react-intl';
import { css } from '@emotion/core';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import TypeformEmbedButton from './2023wk35/TypeformOverlay';
import { OpenSansParagraph } from '../common/typography';

const HeaderLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  opacity: 0.9,
  fontFamily: ['Source Code Pro'].concat(MONO_SYSTEM_FONTS).join(', '),
  fontSize: 12,
  fontWeight: 500,
  color: '#160B2C',
  letterSpacing: 0.5,

  '@media (hover: hover)': {
    '&:not(.ignore-me):hover': {
      opacity: 1,
      textDecoration: 'none',
      backgroundColor: '#D2EEDD',
      color: '#1FA956',
      borderRadius: 30,
      height: 40,
      fontWeight: 'bold',
    },
  },
});

const DropdownLink = styled('div')({
  padding: '0 26px',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  opacity: 0.9,
  fontFamily: ['Source Code Pro'].concat(MONO_SYSTEM_FONTS).join(', '),
  fontSize: 12,
  fontWeight: 500,
  letterSpacing: 0.5,
  cursor: 'pointer',

  '@media (hover: hover)': {
    '&:not(.ignore-me):hover': {
      opacity: 1,
      textDecoration: 'none',
      backgroundColor: '#D2EEDD',
      color: '#1FA956',
      borderRadius: 30,
      height: 40,
      fontWeight: 'bold',
    },
  },
});

const DropdownMenu = styled('div')({
  position: 'absolute',
  top: '100%',
  left: 0,
  right: 'auto',
  minWidth: 200,
  borderRadius: 10,
  zIndex: 1,
});

const shadowStyle = css({
  boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.1)',
  borderRadius: 10,
});

const DropdownMenuItem = styled(Link)({
  display: 'block',
  width: '100%',
  padding: '0.5rem 1rem',
  clear: 'both',
  fontWeight: 400,
  color: '#212529',
  textAlign: 'inherit',
  whiteSpace: 'nowrap',
  backgroundColor: 'transparent',

  '&:hover, &:focus': {
    color: '#1FA956',
    textDecoration: 'none',
    backgroundColor: '#D2EEDD',
    fontWeight: 'bold',
  },

  ':first-of-type': {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },

  ':last-child': {
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
});

const Menu = styled('div')({
  width: 20,

  '&::before': {
    content: "''",
    display: 'block',
    width: '100%',
    height: 2,
    borderRadius: 8,
    backgroundColor: '#d2eedd',
    marginBottom: 5,
  },

  '&::after': {
    content: "''",
    display: 'block',
    width: '80%',
    marginLeft: 'auto',
    height: 2,
    borderRadius: 8,
    backgroundColor: '#d2eedd',
  },
});

const lineStyles = css`
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #e5e5e5;
`;

const darkLineStyles = css`
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #d2eedd26;
`;

const IconStyles = css`
  margin-right: 5px;
  fill: #828181;
`;

const textStyle = css`
  font-size: 12px;
  font-family: ${['Open Sans'].concat(SYSTEM_FONTS).join(', ')};
  font-weight: 600;
  color: #828181;
`;

const serviceStyle = css`
  font-size: 12px;
  font-family: ${['Open Sans'].concat(SYSTEM_FONTS).join(', ')};
  font-weight: 600;
  color: #828181;

  @media (max-width: 670px) {
    display: none;
  }
`;

interface HeaderProps {
  onOpenMobileMenu: () => void;
  isDarkHeader?: boolean;
  categories: ContentfulCategoryEdge[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  aboutPages: any[];
  hideCallToAction?: boolean;
}

export default function Header({
  onOpenMobileMenu,
  isDarkHeader,
  categories,
  aboutPages,
  hideCallToAction,
}: HeaderProps) {
  const localizedLinks: ContentfulHeaderLinks | null = useHeaderLinks();
  const intl = useIntl();

  const [isHoveredLexicon, setIsHoveredLexicon] = useState(false);
  const [isHoveredAbout, setIsHoveredAbout] = useState(false);

  const renderDropDown = (
    title: string,
    children: React.ReactNode,
    onMouseEnter: () => void,
    onMouseLeave: () => void,
    isHovered: boolean
  ) => {
    return (
      <div
        css={{
          position: 'relative',
          display: 'none',
          visibility: 'hidden',
          minHeight: 40,

          '@media (min-width: 896px)': {
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'center',
            visibility: 'visible',
          },
        }}
      >
        <DropdownLink
          css={{
            color: isDarkHeader ? '#FFFDFC' : '#160B2C',
          }}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {title}
          {isHovered ? (
            <FiChevronUp
              size={16}
              css={{
                marginLeft: 5,
                color: '#d2eedd',
              }}
            />
          ) : (
            <FiChevronDown
              size={16}
              css={{
                marginLeft: 5,
                color: isDarkHeader ? '#FFFDFC' : '#160B2C',
              }}
            />
          )}

          <DropdownMenu>
            <div css={{ height: 10 }} />
            <div
              css={[
                shadowStyle,
                {
                  backgroundColor: isDarkHeader ? '#1B0D35' : '#FFFDFC',
                  display: isHovered ? 'block' : 'none',
                },
              ]}
            >
              {children}
            </div>
          </DropdownMenu>
        </DropdownLink>
      </div>
    );
  };

  const renderCategories = () => {
    return categories
      .filter(({ node }) => node.slug !== 'news')
      .map(({ node }) => (
        <DropdownMenuItem
          key={node.id}
          to={`/${node.slug}`}
          css={{
            color: isDarkHeader ? '#FFFDFC' : '#160B2C',
          }}
        >
          {node.title}
        </DropdownMenuItem>
      ));
  };

  const renderAboutPages = () => {
    return aboutPages.map((page) => (
      <DropdownMenuItem
        key={page.id}
        to={`${page.url}`}
        css={{
          color: isDarkHeader ? '#FFFDFC' : '#160B2C',
        }}
      >
        {page.title}
      </DropdownMenuItem>
    ));
  };

  return (
    <Headroom
      wrapperStyle={{
        width: '100%',
      }}
      style={{
        position: 'fixed',
        background: isDarkHeader ? '#160B2C' : '#FFFDFC',

        zIndex: getZIndex('header'),
      }}
      pinStart={250}
      downTolerance={15}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#489f9d',
          padding: 10,
        }}
      >
        <OpenSansParagraph
          css={{
            color: '#fff',
            fontSize: 14,
            paddingLeft: '1rem',
            paddingRight: '1rem',
            opacity: 1,
          }}
        >
          {intl.formatMessage({
            id: 'nav.header.header.announcement',
            defaultMessage:
              'Zum ersten Quartal 2025 übernimmt Bayer die HiDoc Technologies GmbH, das Unternehmen hinter Cara Care für Reizdarm',
          })}{' '}
          <a
            target="_blank"
            rel="noreferrer noopener"
            css={{
              textDecoration: 'underline',
              color: '#fff',
            }}
            href={intl.formatMessage({
              id: 'nav.header.header.announcement.link.url',
              defaultMessage:
                'https://www.bayer.com/media/erste-diga-von-bayer-cara-care-fuer-reizdarm/',
            })}
          >
            {intl.formatMessage({
              id: 'nav.header.header.announcement.link',
              defaultMessage: 'Click here to learn more.',
            })}
          </a>
        </OpenSansParagraph>
      </div>
      <Container css={{ height: '100%' }}>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '10px 0',
            whiteSpace: 'nowrap',
          }}
        >
          <span css={[serviceStyle, { marginRight: 24 }]}>
            Service-Hotline:
          </span>
          <PhoneIcon css={IconStyles} />
          <span css={[textStyle, { marginRight: 18 }]}>030 3080 9546</span>
          <span css={textStyle}>
            {intl.formatMessage({
              id: 'header.hours',
              defaultMessage: '(Mo–Fr 9–18 Uhr)',
            })}
          </span>
          <LanguageSelect
            css={{
              display: 'none',
              visibility: 'hidden',

              '@media (min-width: 896px)': {
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                alignSelf: 'center',
                visibility: 'visible',
                marginLeft: 20,
              },
            }}
            isDarkHeader={isDarkHeader}
          />
        </div>
        <div css={isDarkHeader ? darkLineStyles : lineStyles} />
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '10px 0',
          }}
        >
          <HeaderLink
            className="ignore-me"
            to="/"
            aria-label="Cara Care homepage"
          >
            <img
              src={isDarkHeader ? logoDark : logo}
              alt="Cara Care logo"
              aria-hidden="true"
              css={{
                margin: 0,
                width: 130,
                height: 'auto',
              }}
            />
          </HeaderLink>
          <nav
            css={{
              display: 'none',
              visibility: 'hidden',

              '@media (min-width: 896px)': {
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                alignSelf: 'center',
                visibility: 'visible',
              },
            }}
          >
            {localizedLinks &&
              localizedLinks.links
                .filter((link) => link.isDisplayed)
                .map((link) => (
                  <HeaderLink
                    key={link.id}
                    to={link.url}
                    css={{
                      padding: '0 26px',
                      color: isDarkHeader ? '#FFFDFC' : '#160B2C',
                    }}
                  >
                    {link.text}
                  </HeaderLink>
                ))}

            <HeaderLink
              to="https://www.iberogast.de/"
              css={{
                padding: '0 26px',
                color: isDarkHeader ? '#FFFDFC' : '#160B2C',
              }}
            >
              Iberogast
            </HeaderLink>

            {renderDropDown(
              intl.formatMessage({
                id: 'nav.header.lexicon',
                defaultMessage: 'Lexikon',
              }),
              renderCategories(),
              () => setIsHoveredLexicon(true),
              () => setIsHoveredLexicon(false),
              isHoveredLexicon
            )}

            {renderDropDown(
              intl.formatMessage({
                id: 'nav.header.about.us.title',
                defaultMessage: 'Über uns',
              }),
              renderAboutPages(),
              () => setIsHoveredAbout(true),
              () => setIsHoveredAbout(false),
              isHoveredAbout
            )}
          </nav>

          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {hideCallToAction ? null : (
              <TypeformEmbedButton
                index={0}
                typeformId="bpuzKchE"
                buttonText={intl.formatMessage({
                  id: 'prescription.service.code',
                  defaultMessage: 'REZEPTSERVICE',
                })}
                design="bordered"
                buttonStyle={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  visibility: 'visible',
                  // backgroundColor: '#d2eedd',
                  border: '2px solid #2AAB3C',
                  color: '#2AAB3C',

                  '@media (max-width: 896px)': {
                    margin: '0 20px',
                  },

                  '@media (max-width: 400px)': {
                    display: 'none',
                    visibility: 'hidden',
                  },
                }}
              />
            )}
            <button
              onClick={onOpenMobileMenu}
              aria-label="mobile menu"
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                padding: 0,
                backgroundColor: 'transparent',
                border: 0,
                outline: 0,
                cursor: 'pointer',
                WebkitTapHighlightColor: 'transparent',
                '@media (min-width: 896px)': {
                  display: 'none',
                  visibility: 'hidden',
                },
              }}
            >
              <Menu />
            </button>
          </div>
        </div>
      </Container>
    </Headroom>
  );
}
